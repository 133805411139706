.section-cart .heading-page {
    z-index: 10;
    position: fixed !important;
    top: 0px;
    left: 0px;
    height: 50px;
    width: 100%;
    background-color: #ffffff;
    border-bottom: 1px solid #d1d5d7;
    max-width: 600px;
    left: inherit;
    font-size: 13px;
    color: #212529;
}

.section-cart .m-t-contact-form {
    margin-top: 60px;
}

.section-cart .itm-heading {
    font-weight: 600;
    font-size: 13px;
    text-align: left;
    margin: 4px 2px 14px;
    background-color: #fff;
    /* padding: 0px; */
    height: 36px;
    padding: 2px;
    white-space: pre-wrap;
    -webkit-box-shadow: 1px 1px 12px 1px rgba(50, 50, 50, 0.08);
    -moz-box-shadow: 1px 1px 12px 1px rgba(50, 50, 50, 0.08);
    box-shadow: 1px 1px 12px 1px rgba(50, 50, 50, 0.08);
}

.section-cart .itm-heading .city-txt {
    font-size: 10px;
    text-align: center !important;
    color: #8e9294;
    padding: 0px;
    display: flex;
}

.section-cart .modifier-item {
    border-bottom: none !important;
    margin-top: -14px !important;
    // padding: 6px 10px !important;
    font-style: italic;
    height: 45px;
    margin-left: 2px;
    padding: 3px !important;
}

.lrc-item-box {
    -webkit-box-shadow: 1px 1px 6px 1px rgba(177, 170, 170, 0.59);
    -moz-box-shadow: 1px 1px 6px 1px rgba(177, 170, 170, 0.59);
    box-shadow: 1px 1px 6px 1px rgba(177, 170, 170, 0.59);
}

.section-cart .wrap-list-rc .lrc-item {
    border-bottom: 1px solid #ecececcc;
    padding: 6px 6px;
}


.section-cart .wrap-list-rc .itm-modifier {
    color: #6c757de3;
    margin-left: 10px;
    font-size: 10px;
}

.section-cart .lrc-item:nth-child(even) {
    background-color: #fff;
}

.section-cart .regular-item {
    background-color: #fff;
}

.section-cart .wrap-list-rc .lrc-item .lrc-content {
    position: relative;
    width: 100%;
    padding-left: 4px;
    min-height: 40px;
}

.section-cart .wrap-list-rc .lrc-item .lrc-content .lrc-img {
    width: 115px;
    position: relative;
    top: 2px;
    left: 0;
    overflow: hidden;
    height: 90px;
}

.section-cart .wrap-list-rc .lrc-item .lrc-content .lrc-desc .lrc-title {
    font-weight: 600;
    width: 50%;
    padding-top: 5px;
    font-size: 11px;
}

.section-cart .wrap-list-rc .lrc-item .lrc-button {
    color: #7b7b7b;
    max-width: 100%;
    margin: 10px auto 0px;
}

.section-cart .wrap-list-rc .lrc-item .lrc-button .lrcb-left {
    float: left;
    width: 50%;
}

.wrap-list-rc .lrc-item .lrc-button .lrcb-right {
    float: right;
    width: auto;
}

.section-cart .wrap-list-rc .lrc-item .lrc-button .lrcb-right .itm-add {
    border: 1px solid #ccc;
    color: #4CAF50;
    cursor: pointer;
    display: inline-block;
    padding: 6px 14px 4px 14px;
    background-color: #fff;
    font-size: 12px;
    font-weight: 600;
}

.section-cart .wrap-list-rc .lrc-item .lrc-button .lrcb-right .modifier-notify {
    font-size: 9px;
    text-align: center !important;
    color: #2b2e2f;
    padding: 2px;
    display: flex;
}

.section-cart .modifier-price {
    width: 28px !important;
    text-align: right !important;
    margin-right: -2px !important;
    display: inline-table;
}

.section-cart .wrap-list-rc .lrc-item .lrc-price {
    color: #7b7b7b;
    max-width: 100%;
    margin: 10px auto 0px;
}

.section-cart .wrap-list-rc .lrc-item .lrc-button .lrcb-left .svg-inline--fa {
    font-size: 12px;
    padding-right: 0px;
    color: #464545;
}

.section-cart .wrap-list-rc .lrc-item .lrc-button .lrcb-left .price {
    font-size: 14px;
    font-family: monospace;
    color: #000000;
}

.section-cart .qty-detail {
    float: right;
    margin-right: 26px;
    margin-top: 2px;
    border: 1px solid #ddd;
    padding: 2px;
    width: 24%;
    text-align: center;
}

.section-cart .qty-detail .minus {
    color: #7b7b7b;
    font-size: 12px;
    float: left;
}

.section-cart .qty-detail .plus {
    color: #000000;
    font-size: 12px;
    float: right;
}

.section-cart .qty-detail .qty {
    color: #000;
    font-size: 12px;
    font-weight: 400;
}

.section-cart .price-detail {
    float: right;
    margin-top: 4px;
    // margin-right: 12px;
    text-align: right;
    margin-right: 6px;
    width: 13%;
}

.section-cart .itm-delete {
    float: right;
    margin-top: 4px;
    cursor: pointer;
}

.section-cart .bill-details {
    background-color: #fff;
    position: relative;
    width: 100%;
    padding-left: 8px;
    margin-top: 12px;
    -webkit-box-shadow: 1px 1px 12px 1px rgba(50, 50, 50, 0.08);
    -moz-box-shadow: 1px 1px 12px 1px rgba(50, 50, 50, 0.08);
    box-shadow: 1px 1px 12px 1px rgba(50, 50, 50, 0.08);
}

.section-cart .bill-details .content {
    min-height: 26px;
    font-size: 12px;

}

.section-cart .bill-details .heading {
    font-size: 13px;
    font-weight: 600;
    padding-bottom: 3px;
}

.section-cart .bill-details .content .price {
    float: right;
    margin-right: 10px;
}

.section-cart .bill-details .tax {
    border-top: 1px solid #ddd;
    padding-top: 10px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.section-cart .bill-details .total {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 44px;
}

.section-cart .spec-info {
    background-color: #fff;
    position: relative;
    width: 100%;
    padding: 4px;
    margin-top: 12px;
    -webkit-box-shadow: 1px 1px 12px 1px rgba(50, 50, 50, 0.08);
    -moz-box-shadow: 1px 1px 12px 1px rgba(50, 50, 50, 0.08);
    box-shadow: 1px 1px 12px 1px rgba(50, 50, 50, 0.08);
}

.section-cart .spec-info .instc {
    width: 100%;
    height: 24px;
    border: 1px solid #ddd;
    font-size: 12px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.section-cart .pay-div {
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #078bd1;
    width: 100%;
    font-size: 13px;
    color: #ffffff;
    padding: 10px;
    text-align: center;
    font-size: 13px;
    font-weight: 600;
    height: 44px;
    cursor: pointer;
    max-width: 600px;
    left: inherit;
    border-top: 1px solid #078bd1;
    border-bottom: 1px solid #078bd1;
    background-color: #ffffff;
    box-shadow: 1px 2px 3px 0px #d1d5d7;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
    border: 1px solid #d1d5d7 !important;
    color: #000;
}

.section-cart .pay-div .c-left {
    width: 50%;
    float: left;
    text-align: left;
    padding-left: 4px;
}


.section-cart .pay-div .c-right {
    width: 50%;
    float: left;
    text-align: right;
    padding-right: 4px;
}

.section-cart .rupee {
    height: 21px;
    margin-right: 10px;
    margin-top: -3px;
}




.section-cart input[type="text"]::placeholder {

    /* Firefox, Chrome, Opera */
    text-align: left;
    color: #adadade3;
}

.section-cart input[type="text"]:-ms-input-placeholder {

    /* Internet Explorer 10-11 */
    text-align: left;
    color: #adadade3;
}

.section-cart input[type="text"]::-ms-input-placeholder {

    /* Microsoft Edge */
    text-align: left;
    color: #adadade3;
}

.section-cart .food-type-img {
    width: 12px;
    margin-right: 4px;
    margin-top: -3px;
}

.section-cart .spcecial-instc {
    padding: 4px;
    margin-left: 12px;
    font-size: 11px;
    text-decoration: underline;
    color: #078bd1;
    margin-top: -14px;
}

.special-insc {
    text-align: center;
    // width: 500px;
    padding: 10px;
    background: #fff;
    box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
    color: #000;
    margin: 12px;
    display: inline-block;
}

.special-insc>button {
    width: 90px;
    padding: 10px;
    border: 1px solid #d1d5d7;
    margin: 10px;
    cursor: pointer;
    background: none;
    color: #000;
    font-size: 12px;
}

.special-insc .btn-left {
    float: left !important;
}

.special-insc .btn-right {
    float: right !important;
}

.special-insc>textarea {
    width: 100%;
    min-height: 56px;
    resize: none;
    border-radius: 8px;
    border: 1px solid #ddd;
    padding: 0.5rem;
    color: #666;
    box-shadow: inset 0 0 0.25rem #ddd;

    &:focus {
        outline: none;
        border: 1px solid darken(#ddd, 5%);
        box-shadow: inset 0 0 0.5rem darken(#ddd, 5%);
    }

    &[placeholder] {
        font-style: italic;
        font-size: 0.875rem;
    }
}


.special-insc .itm-name {
    text-transform: capitalize;
    color: #078bd1;
    font-size: 14px;
}

.special-insc-main {
    margin-bottom: 8px;
    margin-top: 8px;
    display: inline-block;
}

.special-insc-desc {
    padding: 2px;
    margin-left: 12px;
    font-size: 10px;
    font-weight: 500;
    color: #078bd1;
    background-color: #f1f3f6;
    width: 186px;
    border-radius: 2px;
    border: 1px solid #d12f8b;
    position: absolute;
    margin-top: -12px; //25px
}

#close-special-insc {
    line-height: 6px;
    width: 14px;
    font-size: 8pt;
    font-family: tahoma;
    margin-top: 1px;
    margin-right: 2px;
    position: absolute;
    top: -8px;
    right: -8px;
    padding: 3px;
    color: #ff0a0a;
    border: 1px solid #d12f8b;
}

.section-cart .delivery {
    border-top: 1px solid #ddd;
    padding-top: 10px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    padding-left: 8px;
    font-size: 12px;
}

.section-cart .delivery .addr-heading {
    font-weight: 600;
}

.section-cart .delivery .addr-type {
    font-size: 13px;
    color: #fd0000;
}

.section-cart .delivery .address {
    font-size: 11px;
    margin-top: 8px;
}

.section-cart .delivery .change-btn {
    border-radius: 0px;
    font-size: 11px;
    background-color: #ffffff;
    border: 1px solid #d12f8b;
    color: #d12f8b;
    float: right;
    margin-right: 6px;
    margin-top: -20px;
    background-color: #ffffff;
    box-shadow: 1px 2px 3px 0px #d1d5d7;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
    border: 1px solid #d1d5d7 !important;
    color: #000;
    font-weight: 600;
}

.section-cart .delivery .choose-btn {
    border-radius: 0px;
    font-size: 11px;
    background-color: #ffffff;
    border: 1px solid #d12f8b;
    color: #d12f8b;
    margin-right: 6px;
    margin-top: -20px;
}

.section-cart .delivery .not-login-msg {
    font-size: 11px;
    color: #078bd1;
    font-weight: 600;
    text-align: center;
}

.section-cart .delivery .not-login-msg .login {
    font-size: 12px;
    color: #d12f8b;
    font-weight: 600;
}
.section-myorders .heading-page {
    z-index: 10;
    position: fixed !important;
    top: 0px;
    left: 0px;
    height: 50px;
    width: 100%;
    background-color: #ffffff;
    border-bottom: 1px solid #d1d5d7;
    max-width: 600px;
    left: inherit;
    font-size: 13px;
    color: #212529;
}

.section-myorders .m-t-contact-form {
    margin-top: 60px;
}


.section-myorders .myorders-txt {
    font-size: 9px;
    text-align: center !important;
    color: #8e9294;
    padding: 0px;
    display: flex;
}


.section-myorders .order-details {
    background-color: #fff;
    position: relative;
    width: 100%;
    padding-left: 6px;
    margin-top: 12px;
    -webkit-box-shadow: 1px 1px 12px 1px rgba(50, 50, 50, 0.08);
    -moz-box-shadow: 1px 1px 12px 1px rgba(50, 50, 50, 0.08);
    box-shadow: 1px 1px 12px 1px rgba(50, 50, 50, 0.08);
}

.section-myorders .order-details .heading {
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 3px;
}

.section-myorders .order-details .content {
    font-size: 12px;
    border-bottom: 1px solid #ddd;
    height: 80px;
    padding: 14px;
    cursor: pointer;

}

.section-myorders .order-details .content .orderno {

    font-size: 14px;
    font-weight: 500;


}

.section-myorders .order-details .content .btn-detail {
    float: right;
    border: 1px solid #ec740f;
    padding: 5px 19px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 14px;

}

.section-myorders .order-details .content .date {
    color: #878788;
}

.section-myorders .order-details .content .status-1 {
    color: #1171d8;
    font-weight: 700;

}

.section-myorders .order-details .content .status-2 {
    color: #058c24;
    font-weight: 700;

}

.section-myorders .order-details .content .status-3 {
    color: #ff0202;
    font-weight: 700;

}

.section-myorders .refresh-icon {
    float: right;
    font-size: 16px;
    color: #000;
}

.section-myorders .gallery-section {
    padding: 6px 9px;
    margin-bottom: 15px;
}

.section-myorders .gallery-section .gall-col {
    width: 100%;
    float: left;
}

.section-myorders .gallery-section .filter-button {
    color: #212529;
    background: transparent;
    border: 0px solid #ffffff;
    padding: 10px 10px;
    font-weight: 400;
    margin-bottom: 5px;
    border: 1px solid #cecece;
    width: 48%;
    margin-left: 2px;
}

.section-myorders .gallery-section .row-gallery {
    margin-left: -5px;
    margin-right: -5px;
    margin-top: -20px;
}

.section-myorders .accordion {
    background-color: #fff;
}

.section-myorders .accordion-heading {
    font-weight: 600;
    font-size: 13px;
    text-align: left;
    margin: 4px 12px 4px;
    padding-left: 2px;
    border-bottom: 1px solid #ddd;
}

.section-myorders .accordion-heading .itm-cnt {
    color: #878788;
    font-size: 10px;
}

.section-myorders .accordion-heading .accordion-toggle {
    display: block;
    padding: 4px 4px;
}

.section-myorders .gallery-section .order-details .btn-detail {
    float: right;
    border: 1px solid #078bd1;
    padding: 5px 19px;
    font-size: 11px;
    font-weight: 500;
    border-radius: 0px;
}

.section-myorders .gallery-section .order-details .status-3 {
    color: #ff0202;
    font-weight: 600;
}

.section-myorders .gallery-section .order-details .status-1 {
    color: #1171d8;
    font-weight: 600;
}

.section-myorders .gallery-section .order-details .status-0 {
    color: #27ab45;
    font-weight: 600;
}

.section-myorders .gallery-section .filter-button.active {
    color: #212529 !important;
    border: 1px solid #d12f8b;
}

.section-myorders .gallery-section .order-details table {
    border-bottom: 1.2px dotted #5f5b5b;
}

.section-myorders .gallery-section .order-details .table td,
.table th {

    border-top: 0px solid #dee2e6;
}

.section-myorders .gallery-section .order-details .btn-bill-detail {
    float: right;
    border-bottom: 1px solid #d12f8b;
    padding: 4px 4px;
    font-size: 10px;
    font-weight: 500;
    /* border-radius: 14px; */
    margin-right: 13px;
    color: #212529;
    ;
}

.section-myorders .no-oders {
    text-align: center;
    font-size: 11px;
    color: #ff0018ab;
    font-weight: 500;
}

.section-myorders .thead-txt {
    font-size: 12px;
    color: #212529;
}

.section-myorders .tbody-txt {
    font-size: 12px;
}

.section-myorders .modifier-group-txt {
    font-size: 11px;
    padding-left: 20px;
}

.section-myorders .modifier-item-txt {
    font-size: 11px;
    padding-left: 24px;
    font-style: italic;
}

.section-myorders .modifier-item-row {
    font-size: 10px;
    font-style: italic;
}

.section-myorders table {
    text-transform: capitalize;
}

.section-myorders .pay-now {
    border: 1px solid #d12f8b;
    padding: 6px 22px;
    font-size: 13px;
    font-weight: 400;
    border-radius: 0.25rem;
    text-align: center;
    width: 50%;
    margin: 1px auto;
    color: #212529;
    /* margin-top: -18px; */
    cursor: pointer;
    display: block;
}

.section-myorders .order-amount {
    font-weight: 600;
    font-size: 12px;
    margin: 54px 2px 3px 14px;
    color: #3f4042c7;
}

.section-myorders .bill-details {
    background-color: #fff;
    position: relative;
    width: 100%;
    padding-left: 8px;
    margin-top: 12px;
    -webkit-box-shadow: 1px 1px 12px 1px rgba(50, 50, 50, 0.08);
    -moz-box-shadow: 1px 1px 12px 1px rgba(50, 50, 50, 0.08);
    box-shadow: 1px 1px 12px 1px rgba(50, 50, 50, 0.08);
}

.section-myorders .bill-details .content {
    min-height: 26px;
    font-size: 12px;

}

.section-myorders .bill-details .heading {
    font-size: 13px;
    font-weight: 600;
    padding-bottom: 3px;
}

.section-myorders .bill-details .content .price {
    float: right;
    margin-right: 10px;
}

.section-myorders .bill-details .tax {
    border-top: 1px solid #ddd;
    padding-top: 10px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.section-myorders .bill-details .total {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 44px;
}

.section-myorders .pay-to-img {
    width: 18%;
    height: auto;
    border: 1px solid #ddd;
    padding: 6px;
    margin-left: 12px;
    float: left;
}

.section-myorders .rupee {
    height: 21px;
    margin-right: 10px;
    margin-top: -3px;
    width: 21% !important;
    border-radius: unset !important;
}

.section-myorders .orgname {
    font-size: 12px;
    font-weight: 500;
    padding-left: 6px;
}

.section-myorders .outlet-name {
    font-size: 11px;
    font-weight: 400;
    padding-left: 6px;
    margin-top: -3px;
    margin-left: 82px;
    display: block;
    color: #8e9294;
}

.section-myorders .paybill {
    height: 21px;
    margin-right: 10px;
    margin-top: -3px;
    width: 21% !important;
    border-radius: unset !important;
}

.section-myorders .proceed-pay {
    text-align: right;
    font-size: 16px;
    color: #d12f8b;
    margin-top: -23px;
}

.section-myorders .bill-pay {
    width: 7%;
    margin-top: -3px;
}

.section-myorders iframe {
    overflow: hidden;
}

.section-myorders .iframe-view {
    border: 0;
    overflow: hidden;
    height: 100%;
    position: absolute !important;
    left: 50%;
    transform: translate(-50%, 0);
}

.section-myorders .section-paynow {
    text-align: center;
    background-color: #607d8b24;
    padding: 0 0 8px;
}

.section-myorders .section-paynow .btn-paynow {
    border: 1px solid #078bd1;
    padding: 6px 12px;
    font-size: 13px;
    font-weight: 600;
    border-radius: 0.25rem;
    text-align: center;
    width: 34%;
    margin: 1px auto;
    color: #000000;
    cursor: pointer;
    display: block;
    // background-color: #078bd1;
    background-color: #ffffff;
    box-shadow: 1px 2px 3px 0px #d1d5d7;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
    border: 1px solid #d1d5d7 !important;
}

.section-myorders .section-paynow .unbill-txt {
    font-size: 12px;
}

.section-myorders .section-paynow .unbill-amount {
    font-size: 16px;
    margin: 6px;
}

.section-myorders .react-tabs__tab-list {
    border-bottom: 1px solid #d1d5d7;
    margin: 0 0 10px;
    padding: 0;
}

.section-myorders .react-tabs__tab--selected {
    background: #fff;
    border-color: #000000;
    color: black;
    border-radius: 5px 5px 0 0;
}

.section-myorders .react-tabs__tab-list {
    margin: 0 0 2px;
}

.section-myorders .sdprofile .sdp-left img {
    width: 44px;
    height: 44px;
    overflow: hidden;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
}

.section-myorders .sdprofile {
    background-color: rgba(255, 255, 255, 0.08);
    position: relative;
    padding-left: 58px;
    margin: 2px;
    border-bottom: 1px dashed #aba7a7;
    margin-bottom: 4px;
    min-height: 116px;
}

.section-myorders .sdprofile .sdp-right {
    padding: 2px 12px 0px 0px;
}

.section-myorders .order-row {
    border: 1px solid #d1d5d7;
    padding: 0px;
    margin: 6px;
    border-radius: 4px;
    margin-bottom: 20px;
    box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
    font-size: 12px;
}

.section-myorders .sdprofile .sdp-left {
    position: absolute;
    margin: 6px 6px 6px 6px;
    left: 0px;
    top: 0px;
}

.section-myorders .order-row .sdprofile .order-no {
    position: absolute;
    margin: 6px 6px 6px 6px;
    left: 0px;
    top: 46px;
}

.section-myorders .order-row .sdprofile .order-no-val {
    font-size: 12px;
    font-weight: 700;
}

.section-myorders .order-row .sdprofile .order-date {
    position: absolute;
    margin: 6px 6px 6px 6px;
    left: 0px;
    top: 66px;
}

.section-myorders .order-row .sdprofile .kot-no {
    position: absolute;
    margin: 44px 6px 6px 6px;
    left: 0px;
    top: 46px;
}

.section-myorders .order-row .sdprofile .order-table-no {
    position: absolute;
    margin: 6px 6px 6px 6px;
    top: 22px;
    // left: 40%;
    right: 0px;
    color: #9C27B0;
}

.section-myorders .order-row .sdprofile .bill-table-no {
    position: absolute;
    margin: 6px 6px 6px 6px;
    top: 22px;
    left: 45%;
    color: #9C27B0;
}

.section-myorders .order-row .sdprofile .repeat-order-btn {
    position: absolute;
    /* margin: 6px 6px 6px 6px; */
    top: 4px;
    right: 4px;
    border: 1px solid #d12f8b;
    padding: 4px 4px;
    font-size: 12px;
    font-weight: 400;
    border-radius: 0.25rem;
    text-align: center;
    width: 27%;
    margin: 1px auto;
    color: #212529;
    cursor: pointer;
    display: block;
}

.section-myorders .order-row .sdprofile .order-stauts {
    position: absolute;
    margin: 6px 6px 6px 6px;
    top: 46px;
    right: 10px;
    font-size: 10px;
}

.section-myorders .order-row .sdprofile .order-status-date {
    position: absolute;
    margin: 6px 6px 6px 6px;
    top: 66px;
    right: 10px;
}

// .section-myorders .order-row .view-item-detail
// {
//     float:right;
// }
.section-myorders .order-row .order-instruction {
    padding-left: 8px;
    margin-bottom: 6px;
}

.section-myorders .order-row .order-delivery-address {
    padding-bottom: 8px;
    padding-left: 8px;
    margin-bottom: 6px;
    border-bottom: 1px solid #ddd;
}

.section-myorders .order-row .item-instruction {
    font-size: 11px;
    padding-left: 24px;
    font-style: italic;
    font-weight: 500;
    color: #078bd1;
}

.section-myorders .order-row .status-1 {
    color: #fff;
    font-weight: 500;
    background-color: #1171d8;
    border-radius: 2px;
    padding: 2px;


}

.section-myorders .order-row .status-2 {
    color: #fff;
    font-weight: 500;
    background-color: #058c24;
    border-radius: 2px;
    padding: 2px;

}

.section-myorders .order-row .status-3 {

    color: #fff;
    font-weight: 500;
    background-color: #ff0202;
    border-radius: 2px;
    padding: 2px;

}

.section-myorders .order-row .status-0 {
    color: #fff;
    font-weight: 500;
    background-color: #27ab45;
    border-radius: 2px;
    padding: 2px;
}

.section-myorders .order-row .itm-cnt {
    color: #878788;
    font-size: 10px;
}

.section-myorders .order-row .sdprofile .bill-no {
    position: absolute;
    margin: 6px 6px 6px 6px;
    left: 0px;
    top: 0px;
}

.section-myorders .order-row .sdprofile .bill-no-val {
    font-size: 14px;
    font-weight: 500;
}

.section-myorders .order-row .sdprofile .bill-date {
    position: absolute;
    margin: 6px 6px 6px 6px;
    left: 0px;
    top: 22px;
}

.section-myorders .order-row .sdprofile .bill-amount {
    position: absolute;
    margin: 6px 6px 6px 6px;
    top: 0px;
    right: 10px;
}

.section-myorders .order-row .sdprofile .bill-amount-val {
    font-size: 14px;
    font-weight: 500;
}

.section-myorders .order-row .sdprofile .bill-stauts {
    position: absolute;
    margin: 6px 6px 6px 6px;
    top: 22px;
    right: 10px;
}

.section-myorders .order-row .bill-sdprofile {
    min-height: 84px;
}

.section-myorders .order-row .sdprofile .bill-pay-now-btn {
    position: absolute;
    /* margin: 6px 6px 6px 6px; */
    top: 46px;
    left: 4px;
    border: 1px solid #d12f8b;
    padding: 4px 4px;
    font-size: 12px;
    font-weight: 400;
    border-radius: 0.25rem;
    text-align: center;
    width: 27%;
    margin: 1px auto;
    color: #212529;
    cursor: pointer;
    display: block;
}

.section-myorders .bill-row {
    border: 1px solid #d1d5d7;
    padding: 0px;
    margin: 6px;
    border-radius: 4px;
    margin-bottom: 20px;
    box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
    font-size: 12px;
}

.section-myorders .order-row .sdprofile .bill-view-ebill {
    position: absolute;
    margin: 6px 6px 6px 6px;
    top: 46px;
    right: 10px;
    color: #fd0000;
    font-weight: 600;
    // border-bottom: 1px solid #d12f8b;
    font-size: 10px;
}

.section-myorders .order-row .sdprofile .bill-mode {
    position: absolute;
    margin: 6px 6px 6px 6px;
    top: 38px;
    left: 0px;
}

.section-myorders .history-result-msg {
    font-size: 14px;
    color: #1171d8;
    font-weight: 700;
    padding: 14px;
    text-align: center;
}

.section-myorders .left-arrow {
    float: right;
    color: #fd0000;
    font-size: 14px;
}

.section-myorders .order-track-time-pay {
    font-size: 9px;
    text-align: center !important;
    color: #8e9294;
    padding: 0px;
    display: flex;
}

.section-myorders .status-heading {
    background-color: #ececec;
    padding: 6px;
    color: #212529;
    // padding-left: 26px;
    margin-top: -2px;
    font-size: 14px;
    text-align: center;
    font-weight: 500;
}

.section-myorders .item-view-heading {
    background-color: #ececec;
    padding: 6px;
    color: #212529;
    padding-left: 26px;
    font-size: 13px;
    font-weight: 500;
}

.section-myorders .row-clear {
    background-color: #6c757d30;
    padding: 4px;
    color: #212529;
    // padding-left: 26px;
    margin-top: 4px;
}

.section-myorders .order-track-placed {
    color: #fff;
    background-color: #fd7b0f;
}

.section-myorders .order-track-accepted {
    background-color: #620fea;
    color: #fff;
}

.section-myorders .order-track-preparing {
    background-color: #ececec;
    color: #fff;
}

.section-myorders .order-track-prepared {
    background-color: #ececec;
    color: #fff;
}

.section-myorders .order-track-foodready {
    background-color: #0077ff;
    color: #fff;
}

.section-myorders .order-track-dispatched {
    background-color: #3b4148;
    color: #fff;
}

.section-myorders .order-track-completed {
    background-color: #20c98f;
    color: #fff;
}

.section-myorders .order-track-delivered {
    background-color: #20c98f;
    color: #fff;
}

.section-myorders .order-track-forward {
    background-color: #000;
    color: #fff;
}

.section-myorders .order-track-rejected {
    background-color: #e63546;
    color: #fff;
}

.section-myorders .order-track-cancelled {
    background-color: #e63546;
    color: #fff;
}

.section-myorders .order-track-order-no {
    font-size: 28px;
    text-align: center;
    padding-top: 14px;
    font-weight: 700;
}

.section-myorders .order-track-order-date-time {
    font-size: 12px;
    text-align: center;
    font-weight: 500;
    color: #078bd1;
}

.section-myorders .order-track-order-service {
    font-size: 13px;
    text-align: center;
    padding-top: 14px;
    font-weight: 700;
}

.section-myorders .order-track-view-item {
    border-radius: 0px;
    font-size: 13px;
    background-color: #ffffff;
    border: 1px solid #d3d6d6;
    color: #d12f8b;
    margin: 4px auto;
    display: block;
}

.section-myorders .order-track-next-btn {
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #fff;
    width: 100%;
    font-size: 13px;
    color: #212529;
    //   padding: 6px;
    text-align: center;
    font-size: 13px;
    font-weight: 600;
    height: 40px;
    cursor: pointer;
    max-width: 600px;
    left: inherit;
    border-top: 1px solid #d1d5d7;
    border-bottom: 1px solid #d1d5d7;
}

.section-myorders .order-track-next-btn-previous {
    float: left;
    width: 50%;
    border-right: 1px solid #000000;
    height: 37px;
    padding-top: 6px;
}

.section-myorders .order-track-next-btn-next {
    padding-top: 6px;
}

//   Track - start
.section-myorders .progress-track {
    padding: 0 8%;
    margin-bottom: 50px;
    display: inline-block;
}

.section-myorders #progressbar {
    margin-bottom: 3vh;
    overflow: hidden;
    color: #000;
    padding-left: 0px;
    margin-top: 3vh
}

.section-myorders #progressbar li {
    list-style-type: none;
    font-size: 0.8rem;
    width: 25%;
    float: left;
    position: relative;
    font-weight: 400;
    color: #000
}

.section-myorders #progressbar #placed:before {
    content: "";
    color: #000;
    width: 20px;
    height: 20px;
    margin-left: 0px !important
}

.section-myorders #progressbar #accept:before {
    content: "";
    color: #000;
    width: 20px;
    height: 20px;
    margin-left: 32%
}

.section-myorders #progressbar #perparing:before {
    content: "";
    color: #000;
    width: 20px;
    height: 20px;
    margin-right: 32%
}

.section-myorders #progressbar #foodready:before {
    content: "";
    color: #989494;
    width: 20px;
    height: 20px;
    margin-right: 0px !important
}

.section-myorders #progressbar #dispatch:before {
    content: "";
    color: #000;
    width: 20px;
    height: 20px;
    margin-left: 32%
}

.section-myorders #progressbar #deliver:before {
    content: "";
    color: #000;
    width: 20px;
    height: 20px;
    margin-left: 32%
}

.section-myorders #progressbar #reject:before {
    content: "";
    color: #000;
    width: 20px;
    height: 20px;
    margin-left: 32%
}

.section-myorders #progressbar #cancelled:before {
    content: "";
    color: #000;
    width: 20px;
    height: 20px;
    margin-left: 32%
}

.section-myorders #progressbar li:before {
    line-height: 29px;
    display: block;
    font-size: 12px;
    background: #989494;
    border-radius: 50%;
    margin: auto;
    z-index: -1;
    margin-bottom: 1vh
}

.section-myorders #progressbar li:after {
    content: '';
    height: 3px;
    background: #989494;
    position: absolute;
    left: 0%;
    right: 0%;
    margin-bottom: 2vh;
    top: 8px;
    z-index: 1
}

.section-myorders .progress-track {
    padding: 0 8%
}

// .section-myorders #progressbar li:nth-child(2):after {
//     margin-right: auto
// }

// .section-myorders #progressbar li:nth-child(1):after {
//     margin: auto
// }

// .section-myorders #progressbar li:nth-child(3):after {
//     float: left;
//    // width: auto;
// }

// .section-myorders #progressbar li:nth-child(4):after {
//     margin-left: auto;
//   //  width: auto;
// }

.section-myorders #progressbar li.active:before,
.section-myorders #progressbar li.active:after {
    background: #d12f8b;
}

.section-myorders .order-track-count-move {
    position: fixed;
    bottom: 49px;
    left: 0;
    width: 100%;
    color: #6e7780;
    text-align: center;
    font-size: 12px;
    font-weight: 600
}


// .section-myorders #three {
//     font-size: 1.2rem
// }

// @media (max-width: 767px) {
//     .section-myorders #three {
//         font-size: 1rem
//     }
// }

// Track - End
.section-address .heading-page {
  z-index: 10;
  position: fixed !important;
  top: 0px;
  left: 0px;
  height: 50px;
  width: 100%;
  background-color: #ffffff;
  border-bottom: 1px solid #d1d5d7;
  max-width: 600px;
  left: inherit;
  font-size: 13px;
  color: #212529;
}

.section-address .address-list ul {
  list-style: none;
  padding-left: 0px;
}

.section-address .address-list ul li {
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.section-address .delivery {

  padding-top: 10px;
  // border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  padding-left: 26px;
  font-size: 12px;
}

.section-address .delivery .addr-heading {
  font-weight: 600;
}

.section-address .delivery .addr-type {
  font-size: 13px;
  color: #212529;
}

.section-address .delivery .address {
  font-size: 11px;
  margin-top: 8px;
}

.section-address .delivery .change-btn {
  border-radius: 0px;
  font-size: 11px;
  background-color: #ffffff;
  border: 1px solid #d12f8b;
  color: #d12f8b;
  float: right;
  margin-right: 6px;
  margin-top: -20px;
  background-color: #ffffff;
  box-shadow: 1px 2px 3px 0px #d1d5d7;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  border: 1px solid #d1d5d7 !important;
  color: #000;
  font-weight: 600;
}

.section-address .selected-address {
  width: 20px;
  float: left;
  height: 27px;
  vertical-align: middle;
  position: relative;
  top: 10px;
  color: #fd0000;
  padding-left: 8px;
}

.section-address .add-address {
  font-size: 13px;
  padding-left: 16px;
  margin-bottom: 10px;
  margin-top: 60px;
  font-weight: 600;
}

.section-address .add-address .plus {
  color: #fd0000;
}

.section-address .delivery-heading {
  background-color: #ececec;
  padding: 6px;
  color: #212529;
  padding-left: 26px;
}

.section-address .address-entry {
  padding: 14px;
  margin-bottom: 38px;
  padding-top: 4px;
  // box-shadow: 0 4px 4px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%) !important;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
}


.section-address .address-entry .form-group {
  margin-bottom: 2px;
}

.section-address .address-entry .form-control {
  font-size: 10px;
  border-color: #d1d5d7;
}

.section-address .save-btn {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #fff;
  width: 100%;
  font-size: 13px;
  color: #212529;
  padding: 6px;
  text-align: center;
  font-size: 13px;
  font-weight: 600;
  height: 40px;
  cursor: pointer;
  max-width: 600px;
  left: inherit;
  border-top: 1px solid #078bd1;
  border-bottom: 1px solid #078bd1;
  background-color: #ffffff;
  box-shadow: 1px 2px 3px 0px #d1d5d7;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  border: 1px solid #d1d5d7 !important;
  color: #000;
  font-weight: 600;
}

.section-address .address-entry .form-control:focus {
  border-color: #d1d5d7;
  outline: 0;
}

.section-address .address-entry .address-type-btn {
  border-radius: 0px;
  font-size: 11px;
  background-color: #ffffff;
  border: 1px solid #d1d5d7;
  color: #000;
  margin-right: 6px;
}

.section-address .address-entry .selected-btn {
  background-color: #d1d5d7;
  color: #000;
  font-weight: 600;
}

.section-address .delivery .right-btn {
  border-radius: 0px;
  font-size: 14px;
  background-color: #ffffff;
  color: #fd0000;
  float: right;
  margin-right: 19px;
  margin-top: -14px;
}

.section-address .address-entry li {
  display: contents;
}

.section-address .delivery-address {
  background-color: #d1d5d7;
  padding: 6px;
  color: #000;
  padding-left: 26px;
  margin-top: -2px;
}
.section-group-members .modal-content {
  border-radius: 0;
  position: absolute;
  // bottom: 0px; 
  bottom: 40px;
}

.section-group-members .modal-title {
  font-size: 13px;
  font-weight: 600;
}

.section-group-members .modal-header {
  padding: 8px 8px;
  // background-color: #ebf9ef;
  border-bottom: 1px solid #d1d5d7;

}

.section-group-members .modal-body {
  padding: 8px;
  max-height: 370px;
  overflow-x: auto;
}

.section-group-members .food-type-img {
  margin-top: -3px;
}

.section-group-members .modal-body .food-type-img {
  margin-left: 6px;
}

.section-group-members .modal-body .modifier-group-li {
  line-height: 12px;
  list-style: none;
}

.section-group-members .modal-body .modifier-group-li .group-name {
  font-size: 12px;
  font-weight: 700;
}

.section-group-members .modal-body .modifier-group-li .minimum-selection {
  font-size: 16px;
  vertical-align: middle;
  margin-left: -12px;
  position: relative;
  margin-right: 5px;
  font-weight: 400;
}

.section-group-members .modal-body .modifier-li {
  line-height: 28px;
  margin-top: 4px;
  list-style: none;
  margin-left: -60px;
}

.section-group-members .modal-body .rate-inr {
  font-size: 11px;
  color: #585252;
}

.section-group-members .modal-footer {
  // -webkit-box-shadow: 1px 1px 12px 1px rgba(177, 170, 170, 0.59);
  // -moz-box-shadow: 1px 1px 12px 1px rgba(177, 170, 170, 0.59);
  // box-shadow: 1px 1px 12px 1px rgba(177, 170, 170, 0.59);
  padding: 2px;
}

.section-group-members .modal-footer .cart {
  //position: fixed;
  bottom: 0;
  left: 0;
  background-color: #fff;
  width: 100%;
  font-size: 13px;
  color: #212529;
  padding: 10px;
  font-weight: 400;
  height: 40px;
  cursor: pointer;
  z-index: 10;
  left: inherit;
  max-width: 600px;
  border-top: 1px solid #078bd1;
  border-bottom: 1px solid #078bd1;
}

.section-group-members .cart .c-left {
  float: left;
}

.section-group-members .cart .c-right {
  float: right;
}

.section-group-members .close {
  float: right;
  font-size: 18px;
  line-height: 1;
  color: #212529;
  text-shadow: 0 1px 0 #fff;
  /* opacity: 0.5; */
}

.section-group-members .refresh-icon {
  float: right;
  font-size: 13px;
  color: #000000;
}

.section-group-members .react-tabs {
  height: 310px;
}

.section-group-members .react-tabs__tab {
  width: 50%;
  text-align: center;
}

.section-group-members .react-tabs__tab-list {
  border-bottom: 1px solid #d1d5d7;
  margin: 0 0 10px;
  padding: 0;
}

.section-group-members .react-tabs__tab--selected {
  background: #fff;
  border-color: #000;
  color: black;
  border-radius: 5px 5px 0 0;
}

.section-group-members .react-tabs__tab-list {
  margin: 0 0 2px;
}

.section-group-members .help-list ul {
  list-style: none;
  padding-left: 0px;
}

.section-group-members .help-list ul li {
  border-bottom: 1px solid #ddd;
  // padding-bottom: 10px;
}

.section-group-members .request {

  padding-top: 12px;
  // border-bottom: 1px solid #ddd;
  padding-bottom: 12px;
  padding-left: 26px;
  font-size: 12px;
}

.section-group-members .request .help-heading {
  font-weight: 600;
}

.section-group-members .request .help-type {
  font-size: 13px;
  color: #212529;
}

.section-group-members .request .help {
  font-size: 11px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.section-group-members .request .change-btn {
  border-radius: 0px;
  font-size: 11px;
  background-color: #ffffff;
  border: 1px solid #d12f8b;
  color: #d12f8b;
  float: right;
  margin-right: 6px;
  margin-top: -20px;
}

.section-group-members .selected-help {
  width: 20px;
  float: left;
  height: 27px;
  vertical-align: middle;
  position: relative;
  top: 10px;
  color: #d12f8b;
  padding-left: 8px;
}

.section-group-members .request .right-btn {
  border-radius: 0px;
  font-size: 14px;
  background-color: #ffffff;
  color: #fd0000;
  float: right;
  margin-right: 19px;
  margin-top: -14px;
}

.section-group-members .request .request-time {
  padding: 6px 0 6px 0;
  color: #8a8c8e;
  font-weight: 400;
  font-size: 11px;
}

.section-group-members .request .status-open {
  color: #ffffff;
  font-weight: 700;
  background-color: #1171d8;
  width: 85px;
  border-radius: 2px;
  padding: 2px;
  text-align: center;
}

.section-group-members .request .status-process {
  color: #ffffff;
  font-weight: 700;
  background-color: #fd7b0f;
  width: 85px;
  border-radius: 2px;
  padding: 2px;
  text-align: center;
}

.section-group-members .request .status-closed {
  color: #ffffff;
  font-weight: 700;
  background-color: #27ab45;
  width: 85px;
  border-radius: 2px;
  padding: 2px;
  text-align: center;
}

.section-group-members .request .status-cancelled {
  color: #ffffff;
  font-weight: 700;
  background-color: #ff0000;
  width: 85px;
  border-radius: 2px;
  padding: 2px;
  text-align: center;
}

.section-group-members .no-data-img {
  text-align: center;
}

.section-group-members .react-tabs__tab-panel {
  max-height: 260px;
  overflow-x: auto;
}


.section-group-members .group-content {
  padding: 9px 22px;
  font-weight: 400;
  // margin: 22px auto;
  color: #3d4152;
  //border-left: 3px solid #d12f8b;	
  font-size: 1.07rem;
  //background: #bae1e0;
}

.section-group-members .group-content::before,
.section-group-members .group-content::after {
  content: "";
  // margin: 0 16px;
  background: #fff;
  height: 2px;
  display: block;
}

.section-group-members .group-content>h6 {
  padding-bottom: 10px;
  margin-top: 20px;
}

.section-group-members .group-list {
  max-height: 250px;
  overflow: auto;
  margin-bottom: 20px;
}

.section-group-members .group-list .people {
  font-size: 14px;
  font-family: monospace;
}

.section-group-members .group-list .people-main {
  font-size: .8rem;
  color: #d12f8b;
}

.section-group-members .group-list .people-content {
  color: #078bd1;
}

.section-group-members .group-list>ul {
  list-style: none;
  background-color: #fff;
  margin-left: -37px;
}

.section-group-members .group-list>ul>li {
  width: 100% !important;
  padding: 8px !important;
  border-bottom: 1px solid #ddd;
}
.section-process .heading-page {
    z-index: 20;
    position: fixed !important;
    top: 0px;
    left: 0px;
    height: 50px;
    width: 100%;
    border-bottom: 1px solid #d1d5d7;
    max-width: 600px;
    left: inherit;
    font-size: 13px;
    color: #212529;
}

// .section-payment .m-t-contact-form
// {
//     margin-top: 60px;
// }


.section-process .payment-txt {
    font-size: 9px;
    text-align: center !important;
    color: #8e9294;
    padding: 0px;
    display: flex;
}


.section-process .gateway-details {
    background-color: #fff;
    position: relative;
    width: 100%;
    padding-left: 6px;
    margin-top: 12px;
    -webkit-box-shadow: 1px 1px 12px 1px rgba(50, 50, 50, 0.08);
    -moz-box-shadow: 1px 1px 12px 1px rgba(50, 50, 50, 0.08);
    box-shadow: 1px 1px 12px 1px rgba(50, 50, 50, 0.08);
}

.section-process .gateway-details .heading {
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 3px;
}

.section-process .gateway-details .content {
    font-size: 12px;
    border-bottom: 1px solid #ddd;
    height: 49px;
    padding: 14px;
    cursor: pointer;

}

.section-process .gateway-details .content img {
    height: 12px;

}

.section-process .gateway-details .content .payment-name {
    padding-left: 10px;
    vertical-align: middle;
    font-size: 16px;
    font-weight: 500;
}

.section-processverification .heading-page {
    z-index: 10;
    position: fixed !important;
    top: 0px;
    left: 0px;
    height: 50px;
    width: 100%;
    background-color: #ffffff;
    border-bottom: 1px solid #ddd;
    padding: 8px 15px 14px 10px;
    max-width: 600px;
    left: inherit;
}

.section-processverification .m-t-contact-form {
    margin-top: 100px;
}


.section-processverification .payment-txt {
    font-size: 9px;
    text-align: center !important;
    color: #8e9294;
    padding: 0px;
    display: flex;
}


.section-processverification .message-details {
    background-color: #fff;
    width: 100%;
    margin: 0 auto;
    height: 250px;
    -webkit-box-shadow: 1px 1px 12px 1px rgba(50, 50, 50, 0.08);
    -moz-box-shadow: 1px 1px 12px 1px rgba(50, 50, 50, 0.08);
    box-shadow: 1px 1px 12px 1px rgba(50, 50, 50, 0.08);
}

.section-processverification .message-details .content {
    font-size: 12px;
    // border-bottom: 1px solid #ddd;   
    padding: 80px;
    cursor: pointer;
}

.section-processverification .message-details .msg {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
}

.section-processverification .message-details .msg-order-id {
    padding: 15px;
    text-align: center;
    color: #868585;
    font-size: 13px;
    font-weight: 500;
}

.section-processverification .message-details .btn {
    padding: 10px 76px;
    display: block;
    margin: 0 auto;
    background-color: var(--pink);
    margin-top: 80px;
}

.section-processverification .message-details .btn-orders {
    padding: 15px;
    text-align: center;
    color: #868585;
    font-size: 13px;
    font-weight: 500;
}
.section-ordersuccess .heading-page {
    z-index: 10;
    position: fixed !important;
    top: 0px;
    left: 0px;
    height: 50px;
    width: 100%;
    background-color: #ffffff;
    border-bottom: 1px solid #ddd;
    padding: 8px 15px 14px 10px;
    max-width: 600px;
    left: inherit;
    font-size: 13px;
    color: #212529;
    border-bottom: 1px solid #d1d5d7;

}

.section-ordersuccess .m-t-contact-form {
    margin-top: 100px;
}


.section-ordersuccess .payment-txt {
    font-size: 9px;
    text-align: center !important;
    color: #8e9294;
    padding: 0px;
    display: flex;
}


.section-ordersuccess .message-details {
    background-color: #fff;
    width: 86%;
    border-radius: 14px;
    margin: 0 auto;
    -webkit-box-shadow: 1px 1px 12px 1px rgba(50, 50, 50, 0.08);
    -moz-box-shadow: 1px 1px 12px 1px rgba(50, 50, 50, 0.08);
    box-shadow: 1px 1px 12px 1px rgba(50, 50, 50, 0.08);
}

.section-ordersuccess .message-details .content {
    font-size: 12px;
    border-bottom: 1px solid #ddd;
    padding: 4px;
    cursor: pointer;
    margin-top: 104px;
}

.section-ordersuccess .message-details .msg {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    padding-bottom: 16px;
}

.section-ordersuccess .message-details .msg-order-id {
    padding: 15px;
    text-align: center;
    color: #868585;
    font-size: 12px;
    font-weight: 500;
}

.section-ordersuccess .message-details .btn {
    padding: 6px 36px;
    display: block;
    margin: 0 auto;
    background-color: #fff;
    margin-top: 42px;
    border-radius: 0;
    margin-bottom: 10px;
    border: 1px solid #d12f8b;
    /* padding: 9px 22px; */
    color: #212529;
}

.section-ordersuccess .message-details .btn-orders {
    padding: 13px;
    text-align: center;
    color: #868585;
    font-size: 13px;
    font-weight: 400;
}